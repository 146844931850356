<template>
  <main class="browser-outdated main-container">
    <transition name="modal">
      <div v-if="browserUpToDate">
        <h2>{{ $t('browserupdate.browser_uptodate') }}</h2>
      </div>
    </transition>
    <template v-if="!browserUpToDate">
      <div class="container--slim">
        <h2>{{ $t('browserupdate.outdated_browser') }}</h2>
      </div>
      <div class="container--slimmer">
        <div v-html="$t('browserupdate.text')" />
      </div>
      <div class="container--slim">
        <div class="cards">
          <div
            v-for="(browser, i) in browsers"
            :key="i"
            :class="{ 'card--highlight': !!browser.recommended }"
            class="card"
          >
            <div
              v-if="!!browser.recommended"
              class="card__badge"
            >
              {{ $t('browserupdate.recommended') }}
            </div>
            <img :src="require(`@/assets/${browser.logo}`)">

            <div class="card__title">
              {{ browser.name }}
            </div>

            {{ $t('browserupdate.available_for') }}

            <div class="availability">
              <component
                :is="require(`@/assets/${os[osName].logo}`).default"
                v-for="(osName, j) in browser.availability"
                :key="i+j"
              />
            </div>

            <app-button
              v-if="isIOS && !!browser.link_ios"
              :id="getGtmId('browserupdate_ios')"
              :label="$t('browserupdate.download_now')"
              type="secondary"
              centered
              @click="open(browser.link_ios)"
            />
            <app-button
              v-else-if="isAndroid && !!browser.link_android"
              :id="getGtmId('browserupdate_android')"
              :label="$t('browserupdate.download_now')"
              type="secondary"
              centered
              @click="open(browser.link_android)"
            />
            <app-button
              v-else-if="!isAndroid && !isIOS && !!browser.link_desktop"
              :id="getGtmId('browserupdate_desktop')"
              :label="$t('browserupdate.download_now')"
              type="secondary"
              centered
              @click="open(browser.link_desktop)"
            />
          </div>
        </div>
      </div>
    </template>
  </main>
</template>

<script>
import Vue from 'vue';
import AppButton from '@/components/common/Button';

export default {
  name: 'BrowserUpdate',

  components: {
    AppButton,
  },

  data: () => ({
    checkBuoInterval: null,
    browserUpToDate: false,
    os: {
      windows: {
        name: 'Windows',
        logo: 'img/icons/windows.svg',
      },
      apple: {
        name: 'Apple',
        logo: 'img/icons/mac.svg',
      },
      linux: {
        name: 'Linux',
        logo: 'img/icons/linux.svg',
      },
    },

    browsers: [
      {
        recommended: true,
        logo: 'img/chrome.png',
        link_desktop: 'https://google.com/chrome/',
        link_android: 'https://play.google.com/store/apps/details?id=com.android.chrome',
        link_ios: 'https://google.com/chrome/',
        name: 'Google Chrome',
        availability: ['windows', 'apple', 'linux'],
      },
      {
        recommended: false,
        logo: 'img/safari.png',
        link_desktop: 'https://support.apple.com/downloads/safari',
        link_android: '',
        link_ios: 'https://support.apple.com/downloads/safari',
        name: 'Apple Safari',
        availability: ['apple'],
      },
      {
        recommended: false,
        logo: 'img/firefox.png',
        link_desktop: 'https://mozilla.org/firefox/',
        link_android: 'https://play.google.com/store/apps/details?id=org.mozilla.firefox',
        link_ios: 'https://mozilla.org/firefox/',
        name: 'Mozilla Firefox',
        availability: ['windows', 'apple', 'linux'],
      },
      {
        recommended: false,
        logo: 'img/edge.png',
        link_desktop: 'https://www.microsoft.com/edge',
        link_android: 'https://play.google.com/store/apps/details?id=com.microsoft.emmx',
        link_ios: 'https://www.microsoft.com/edge',
        name: 'Microsoft Edge',
        availability: ['windows'],
      },
    ],
  }),

  computed: {

    isIOS() {
      return /iPhone|iPad/i.test(navigator.userAgent);
    },

    isAndroid() {
      return /Android/i.test(navigator.userAgent);
    },
  },

  mounted() {
    this.checkBuoInterval = setInterval(this.checkBuo, 1000);

    if (document.getElementsByTagName('main')[0]) {
      Vue.browserUpdate.appendContainer(document.getElementsByTagName('main')[0]);
    }
  },

  methods: {
    open(url) {
      return window.open(url, '_blank');
    },

    checkBuo() {
      // eslint-disable-next-line
      if (!window['_buorgres']) return;
      // eslint-disable-next-line
      this.browserUpToDate = (window['_buorgres'].hide_reasons || []).length && !window['_buorgres'].test;
      clearInterval(this.checkBuoInterval);
    },
  },
};

</script>

<style lang="scss" scoped>

.browser-outdated {
  font-size: $font-size-s;
  background-color: black;


  h2 {
    font-family: $font-family-regular;
    margin-bottom: 2rem;
  }

  .cards {
    display: block;

    @include breakpoint(medium) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
    }
  }

  .card {
    flex: 0;
    margin: 2vw auto;
    flex-basis: 180px;
    font-size: $font-size-xs;

    &__title {
      font-size: $font-size-s;
      margin-bottom: 1rem;

      @include breakpoint(medium) {
        margin-bottom: 3rem;
      }
    }

    img {
      max-width: unquote('min(64%, 90px)');
      margin-bottom: 1rem;
    }

    .button {
      margin: 1rem auto 0;
      font-size: $font-size-xxs;
    }
  }

  .availability {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0.35rem;
      width: 18px;
      height: 18px;
    }
  }
}

</style>
